import React, { useState } from 'react';
import './Investor.css';
import { useNavigate } from 'react-router-dom';
import logo from '../../assets/logo.png';
function Entrepreneur() {
    const [answers, setAnswers] = useState({});
    const [valuation, setValuation] = useState(null);
    const [error, setError] = useState(null);
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const questions = [
        { id: 'E1', text: '创业者经常会问那些挑战现状的问题' },
        { id: 'E2', text: '创业者的好多商业创意来自于观察周围的世界' },
        { id: 'E3', text: '创业者向来敢做敢为，总是在寻找各种新体验' },
        { id: 'E4', text: '创业者有一个彼此互动频繁且能够产生新想法的大关系网' },
        { id: 'E5', text: '创业者会从看似不相关的领域中发现联系，并找到创意' },
        { id: 'E6', text: '创业者有强烈的改变世界的意愿' },
        { id: 'O1', text: '该公司所在行业发展空间很大' },
        { id: 'O2', text: '该公司产品或服务市场潜力巨大' },
        { id: 'O3', text: '该公司所用技术目前已经基本成熟' },
        { id: 'O4', text: '该公司产品领域还没有竞争对手进入' },
        { id: 'M1', text: '相比于其他公司，该公司的企业家才能最适合从事本行业' },
        { id: 'M2', text: '相比于其他公司，该公司掌握的核心技术最适合这个产品' },
        { id: 'M3', text: '与同行业的其他公司相比，该公司发展速度将会更快' },
        { id: 'M4', text: '总体上，该公司创业团队能力完全匹配已出现的创新机会' },
        { id: 'I1', text: '该公司的技术是独一无二的' },
        { id: 'I2', text: '该公司开发的产品会改变整个行业' },
        { id: 'I3', text: '该公司一旦成功，对现有的公司将是毁灭性的打击' },
        { id: 'I4', text: '不管从哪个角度，该公司的创新都是颠覆性的' },
        { id: 'T', text: '预测该公司第一个正盈利年度发生在几年后？' },
        { id: 'V', text: '盈利预测（单位：万元）' },
    ];

    const handleChange = (e, questionId) => {
        let value = e.target.value;

        if (value === '') {
            setAnswers({ ...answers, [questionId]: value });
            return;
        }

        value = Number(value);

        if (questionId === 'T') {
            if (value >= 0 && Number.isInteger(value)) {
                setAnswers({ ...answers, [questionId]: value });
            }
        } else if (questionId === 'V') {
            if (value >= 0) {
                setAnswers({ ...answers, [questionId]: value });
            }
        } else {
            if (value >= 0 && value <= 100) {
                setAnswers({ ...answers, [questionId]: value });
            }
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        setError(null);

        try {

            const numericAnswers = {};
            for (const [key, value] of Object.entries(answers)) {
                numericAnswers[key] = Number(value);
            }

            const modifiedAnswers = {
                ...numericAnswers,
                V: numericAnswers.T,
                T: numericAnswers.V
            };
            const response = await fetch('/api/calculate-value', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(modifiedAnswers),
            });

            const data = await response.json();

            if (data.success) {
                navigate(`/result?value=${data.message}`);
            } else {
                setError('估值计算失败：' + data.message);
            }
        } catch (error) {
            console.error('估值计算错误:', error);
            setError('估值计算失败，请检查网络连接');
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <>
            <header className="header">
                <img src={logo} alt="Valuengine Logo" className="logo" />
                <div className="headewwr" >我是投资者</div>
                <button className="back-button" onClick={() => window.history.back()}>返回</button>
            </header>

            <div className="home1" style={{
                marginBottom: "50px"
            }}>
                <div className="main-buttons1">
                    <a className="main-button1">我是投资者</a>
                </div>



            </div>
            <div className="entrepreneur">

                <form onSubmit={handleSubmit}>
                    <table>
                        <thead>
                            <tr>
                                <th className="col-variable">变量名</th>
                                <th className="col-question">题项</th>
                                <th className="col-score">赋值（按同意程度<br />打分 0-100）</th>
                            </tr>
                        </thead>
                        <tbody>
                            {questions.map((question) => (
                                <tr key={question.id}>
                                    <td className="col-variable">{question.id}</td>
                                    <td className="col-question">{question.text}</td>
                                    <td className="col-score">
                                        <input
                                            type="number"
                                            value={answers[question.id] !== undefined ? answers[question.id] : ''}
                                            onChange={(e) => handleChange(e, question.id)}
                                            min="0"
                                            max={question.id !== 'T' && question.id !== 'V' ? "100" : undefined}
                                            required
                                        />
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                    <div className="button-container">
                        <button type="submit" disabled={isLoading}>
                            {isLoading ? '计算中...' : '提交'}
                        </button>
                    </div>
                </form>

            </div>
        </>
    );
}

export default Entrepreneur;